<template>
  <div id="favoritesHeader">
    <div v-if="reloading" class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <ul class="list-unstyled" v-if="!reloading">
      <li class="favorites__row" v-for="offer in favorites">
        <figure class="favorites__image" v-bind:class="{'lazy-background': hasPhotos(offer), 'favorites__image--searching': (!hasPhotos(offer) && !offer.is_offer)}" v-bind:data-src="photoUrl(offer)" v-bind:style="photoBackground(offer)" v-on:click="openOffer(offer.id)"></figure>
        <div class="favorites__user" v-bind:class="['favorites__user--'+offer.user.user_type.type]" v-bind:style="userBackground(offer.user)"></div>
        <div class="favorites__address" v-on:click="openOffer(offer.id)">
          <strong class="catalog__ribbon">{{offer.ribbon}}</strong>
          <small>{{offer.ad_addresses[0].place.address_name}}</small>
          <div class="catalog__fields">
            <div class="catalog__field" v-if="field.name != 'Price' && field.on_list && field.active && field.icon" v-for="field in offer.ad_fields">
              <component :is="'offerList'+field.type.replace('::', '')" :field="field"></component>
            </div>
          </div>
        </div>
        <div class="favorites__price">
          <div v-if="offer.price">
            {{offer.price}}
          </div>
          <div v-else class="favorites__price__nego">
            {{i18n('offer.price_is_negotiable')}}
          </div>
          <small><span v-if="!offer.in_local_currency">{{offer.local_price}}</span><span v-else>&nbsp;</span></small>
        </div>
        <button class="btn favorites__remove" type="button" v-on:click="remove(offer)"><i class="fas fa-trash"></i></button>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'
import lazyLoad from '../customs/lazy_load';
import * as components from './offer_fields'
import createChannel from "../customs/cable"
import i18nmixin from '../customs/i18nmixin'
import sweetAlert from 'sweetalert2'

export default {
  name: 'header-favorites',
  props: [],
  mixins: [i18nmixin],
  components: {
    ...components
  },
  data: function() {
    return {
      reloading: true,
      favorites: []
    }
  },
  mounted: function(){
    this.fetchFavorites();
    this.createChannel();
  },
  watch: {},
  methods: {
    fetchFavorites() {
      this.reloading = true;
      window.axios.get(window.langtag_path+'/api/favorites.json')
      .then((response) => {
        this.reloading = false;
        this.favorites = response.data.ads;
        document.getElementById("favoritesCount").innerHTML =  response.data.total;
        if(this.favorites.length > 0) {
          document.getElementById("favoritesDropdown").style.display = '';
        } else {
          document.getElementById("favoritesDropdown").style.display = 'none';
        }
        setTimeout(function(){
          lazyLoad('#favoritesHeader');
        }, 0);
      })
    },
    hasPhotos(ad) {
      return ad.ad_photos && ad.ad_photos.length > 0
    },
    photoUrl(ad) {
      if(ad.ad_photos && ad.ad_photos.length > 0) {
        return ad.ad_photos[0].url;
      }
    },
    photoBackground(ad) {
      if(ad.ad_photos && ad.ad_photos.length > 0) {
        return {'background-image': 'url(\''+ad.ad_photos[0].small_url+'\')'};
      }
    },
    userBackground(user) {
      if(user.photo_url) {
        return {'background-image': 'url(\''+user.photo_url+'\')'};
      }
    },
    openFavorites() {
      if(window.catalog) {
        window.catalog.$router.push({name: 'favorites', params: {lang: window.langtag}});
      } else {
        window.location.href = window.langtag_path+'/catalog/favorites';
      }
    },
    openOffer(offer_id) {
      if(window.catalog) {
        window.catalog.$router.push({name: 'offer', params: {lang: window.langtag, id: offer_id}});
      } else {
        window.location.href = window.langtag_path+'/catalog/offer/'+offer_id;
      }
    },
    remove(offer) {
      sweetAlert.fire({
        title: this.i18n('general.are_you_sure'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.i18n('general.yes'),
        cancelButtonText: this.i18n('general.no'),
      }).then(result => {
        if (result.value) {
          window.axios.delete(window.langtag_path+'/favorites/'+offer.id+'.json')
          .then((response) => {
            this.fetchFavorites();
          }).catch(function (error) {
            // handle error
          });
        }
      });
    },
    createChannel() {
      var self = this;
      var channel = createChannel({channel: "FavoritesChannel", chat_id: window.key}, {
        received(data) {
          self.fetchFavorites();
        }
      });
    },
  }
}
</script>
