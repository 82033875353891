import Vue from 'vue'
import HeaderFavorites from '../components/header_favorites.vue'
import FavoritesList from '../components/favorites_list.vue'
import VueRouter from 'vue-router'
import underscore from 'vue-underscore'
Vue.use(underscore)
Vue.use(VueRouter)
document.addEventListener('DOMContentLoaded', () => {
  const el = document.getElementById('favoriteAdsHeader');
  if(el) {
    var app = new Vue({
      el,
      render: h => h(HeaderFavorites)
    });
  }
  // var el = document.getElementById('favoriteOffersList');
  if(document.getElementById('favoriteOffersList')) {
    var app = new Vue({
       el: '#favoriteOffersList',
      render: h => h(FavoritesList)
    });
  }
});
